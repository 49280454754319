.coming-soon{
    // padding-top: 200px;
    // padding-top: 50px;
    .div-1{
        // padding: 100px 0;
        // height: 800px;
        // padding: 140px 0;
        padding-top: 192px;
    }
    h3{
        letter-spacing: 5px;
    }
    h1{
        font-size: 56px;
        font-weight: 500;
        line-height: 66px;
        letter-spacing: -0.05em;
        margin-bottom: 0;
    }
    .span-1{
        font-size: 16px;
        font-weight: 400;
        color: #727272;
        margin-bottom: 0;
        margin-top: 38px;
    }
    .exploreButton{
        margin-top: 38px;
        height: 43px;
        width: 189px;
        border-radius: 24px;
        background: #21bfca;
        font-size: 16px;
        color: black !important;
        border: none ;
        span{
            padding: 9px
        }
    }
    .exploreButton:hover , .exploreButton:active{
        color: #21bfca !important;
        border: none;
        background-color: #fff;
        border: 1px solid #21bfca;
    }
    img{
        // width: 100%;
        margin-top: 130px;
    }
}