.section1{
    .div-1{
        padding: 100px 0;
    }
    h3{
        letter-spacing: 5px;
    }
    h1{
        font-size: 56px;
        font-weight: 500;
        line-height: 66px;
        letter-spacing: -0.05em;
    }
    .exploreButton{
        height: 48px;
        width: 184px;
        border-radius: 24px;
        padding: 12px 24px 12px 32px;
        background: #21bfca;
        font-size: 16px;
        color: black !important;
        border: none ;
        span{
            padding: 9px 0;
        }
    }
    .exploreButton:hover , .exploreButton:active{
        color: #21bfca !important;
        border: none;
        background-color: #fff;
        border: 1px solid #21bfca;
    }
    img{
        width: 100%;
    }
}