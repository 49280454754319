.section4{
    h1{
        font-size: 48px;
        font-weight: 400;
        line-height: 72px;
        letter-spacing: -0.05em;
        text-align: center;
        color: #333333;
        margin-bottom: 0;
    }
    p{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        color: #333333;
    }
    .dashboardDiv{
        background: #f5f5f7;
        border-radius: 24px;
        margin: 25px 50px;
        padding-top: 50px;
}
    
    img{
        background: #F5F5F7;
        border-radius: 24px;
        width: 75%;

    }
    .signUpButton{
        height: 48px;
        width: 221px;
        border-radius: 24px;
        padding: 10px 32px;
        background-color: #21bfca;
        font-size: 16px;
        margin: 80px 0;
        color: #000;
        border: none;
    }
    .signUpButton:hover , .signUpButton:active{
        color: #21bfca;
        border: 1px solid #21bfca;
        background-color: #fff;
    }
}
@media screen and (max-width :767px) {
    .section4{
        .dashboardDiv{
            margin: 25px 10px;
            padding: 0;
            img{
                width: 100%;
            }
        }
    }
}