.explore-more-main{
    padding: 128px 284px;
    text-align: left;
    h1{
        font-size: 56px;
    }
    a{
        text-decoration: underline;
        color: #21BFCA;
    }
    p{
        font-size: 16px;
    }
    ol{
        margin-top: 62px;
        padding-left: 20px;
        margin-bottom: 70px;
    }
    li{
        font-size: 16px;
    }
    h2{
        font-size: 36px;
    }
}