.topBar{
    background-color: transparent;
.ant-layout-header{
    background-color: #fff;
}
.ant-menu-horizontal{
    border: none;
    justify-content: center;
    font-size: 16px;
}
.loginButton{
    width: 88px;
    padding: 8px 24px;
    height: 40px;
    border-radius: 25px;
    border: 1px solid rgba(51, 51, 51, 1);
    font-size: 16px;
}
}
@media screen and (max-width : 767px) {
    .ant-layout-header{
        padding: 0 15px;
        .ant-col-sm-18,.ant-col-xs-18{
            text-align: left;
        }
    }
}