.section7{
    background: #2F2D37;
    height: 311px;
    padding: 55px 0;
    h1{
        font-size: 48px;
        font-weight: 400;
        line-height: 72px;
        letter-spacing: -0.05em;
        text-align: center;
        color: #fff;
        margin-bottom: 0;
    }
    p{
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        color: #fff;
        margin-top: 8px;
    }
    img{
        // background: #F5F5F7;
        border-radius: 24px;
    }
    .facilitatorButton{
        height: 48px;
        // width: 166px;
        width: 241px;
        border-radius: 24px;
        padding: 6px 24px;
        font-size: 16px;
        background-color: #5865F2;
        color: #fff;
        border: none;
        margin-top: 20px;
        span{
            padding: 9px;
        }
    }
    .facilitatorButton:hover , .facilitatorButton:active{
        color: #fff !important;
        // border: 1px solid #fff !important;
        border: none;
    }
}
.contactModal{
    .ant-modal-header{
        border: none;
        padding-bottom: 0;
        border-radius: 8px;
    }
    .ant-modal-content{
        border-radius: 8px;
    }
    .ant-modal-title{
        font-size: 36px;
        font-weight: 600;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: center;
        color: #333333;
        padding: 20px 15px 0;
    }
    .ant-modal-body{
        padding-top: 0;
    }
    p{
        font-size: 20px;
        font-weight: 400;
        text-align: center;
    }
    form{
        text-align: center;
    }
    input{
        height: 51px;
        border-radius: 4px;
        width: 570px;
    }
    textarea{
        width: 570px;
        border-radius: 4px;    
    }
    .sendButton{
        width: 570px;
        height: 48px;
        background: #21BFCA;
        border-radius: 24px;
        border: none;
        color: #000;
    }
}
@media screen and (min-width:768px) and  (max-width : 1024px) {
.section7{
    h1{
        font-size: x-large;
    }
    p{
        font-size: medium;
    }
    .facilitatorButton{
        font-size: medium;
    }
}    
}
@media screen and (max-width:767px){
    .section7{
        h1{
            font-size: large;
        }
        p{
            font-size: medium;
        }
        .facilitatorButton{
            font-size: medium;
        }
    }
}