.section6{
    margin: 5% 0;
    position: relative;
    // height: 580px;
    h1{
        font-size: 48px;
        font-weight: 400;
        line-height: 72px;
        letter-spacing: -0.05em;
        text-align: center;
        color: #fff;
        margin-bottom: 0;
        span{
            color: #21bfca !important;
        }
    }
    p{
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        color: #fff;
        width: 78%;
        text-align: center;
        margin: 0 10%;
    }
    .buyDeviceButton{
        height: 48px;
        width: 134px;
        border-radius: 24px;
        padding: 12px 24px;
        background-color: #21bfca !important;
        font-size: 16px;
        margin: 15px 0 0 10px;
        color: #000 !important;
        border: none !important;
        span{
            padding: 9px;
        }
    }
    .buyDeviceButton:hover , .buyDeviceButton:active{
      color: #21bfca !important;
      background-color: #fff !important;
      border: 1px solid #21bfca !important;
    }
    header img {
        object-fit: cover;
        filter: brightness(.4);
        transition: width 2s ease-in-out;

      }
      section {
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
      }
}
@media screen and (max-width:1040px) {
    .section6{
        h1{
            font-size: xx-large;
        }
        section{
            transform: unset;
            left: 0% !important;
        }
        img{
            width: 100% !important;
        }
    }
}
@media screen and (max-width:767px) {
    .section6 {
        h1{
            font-size: x-large;
        }
        p{
            font-size: medium;
        }
        button{display: none;
        }
        img{
            height: 35vh;
        }
    }
    
}