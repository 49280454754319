.section8{
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, #FCFCFC 100%);
    padding: 5% 0 15%;
    h2{
        font-family: Roboto;
        font-size: 36px;
        font-weight: 600;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
        margin: 25px 0;
    }
    p{
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 45px;
       }
    a{
        color: #333333;
        text-decoration: underline;
    }
    .ant-collapse-borderless {
    background-color: transparent;
    }
    .ant-collapse-header{
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;

    }
    .ant-collapse-content-box{
        text-align: justify;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #333333;
    }
}