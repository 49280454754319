.section5{
    h1{
        font-size: 48px;
        font-weight: 400;
        line-height: 72px;
        letter-spacing: -0.05em;
        text-align: center;
        color: #333333;
        margin-bottom: 0;
    }
    p{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        color: #333333;
    }
    img{
        background: #F5F5F7;
        border-radius: 24px;
        // width: auto;
    }
    .facilitatorButton{
        height: 48px;
        width: 221px;
        border-radius: 24px;
        padding: 10px 32px;
        background-color: #21bfca;
        font-size: 16px;
        margin: 50px 0;
        color: #000;
        border: none;
        span{
            padding: 9px;
        }
    }
    .facilitatorButton:hover , .facilitatorButton:active{
        color: #21bfca !important;
        border: 1px solid #21bfca !important;
        background-color: #fff;
    }
    .mapDiv{
        margin: 0 25px;
    }
}
@media screen and (max-width:1050px){
    .section5{
        .mapDiv{
            margin: 0 10px;
        img{
            width: 100%;
        }
    }

    }
}