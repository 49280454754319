.section2{
    background: #F5F5F7;
    padding: 60px 0;
    .lower-row{
        margin:25px 50px !important; 
    }
    .heading-1{
        font-size: 48px;
        font-weight: 400;
        line-height: 72px;
        letter-spacing: -0.05em;
        text-align: center;
    }
    .rectangle-1{
        background-color: white;
        margin: 0 60px;
        // padding: 145px 0px;
        align-items: center;
        height: 430px;
        border-radius: 24px;
        .heading-2{
            font-size: 32px;
            font-weight: 500;
            line-height: 38px;
            letter-spacing: -0.03em;
            text-align: center;
        }
        p{
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            color: #727272;
            width: 70%;
            text-align: justify;
            margin: 0 15%;
        }
        .investingButton{
            height: 39.82300567626953px;
            width: 151px;
            border-radius: 24px;
            padding: 10px 24px 11px 24px;
            margin-top: 20px;
            color: #333333;
            border: 1px solid #333333;
            span{
                padding: 5px;
            }
        }
    }
    .triangle-2{
        padding: 8px 0;
        h1{
        font-size: 32px;
        font-weight: 500;
        line-height: 38px;
        letter-spacing: -0.03em;
        text-align: center;
        }
        p{
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
            color: #727272;
            padding: 0 75px;
        
        }
        .buyButton{
            height: 40px;
            width: 125px;
            border-radius: 24px;
            padding: 10px 24px 11px 24px;
            border: 1px solid #333333;
            margin-top: 50px;
            color: #000;
            span{
                padding: 5px;
            }
        }
        .buyButton:hover,.buyButton:active{
            color: #000;
        }
    }
    .triangle-3{
        padding: 8px 0;
        h1{
            font-size: 32px;
            font-weight: 500;
            line-height: 38px;
            letter-spacing: -0.03em;
            text-align: center;
            }
            p{
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: center;
                color: #727272;
                padding: 0 75px;
            }
            img{
                margin-top: 50px;
            }
    
    }
    .inner-div{
        padding: 7% 0;
        background:#fff;
        border-radius:24px;
        height:480px
    
    }
}
@media screen and (max-width : 767px) {
    .rectangle-1{
        margin: 0 10px !important;
    }
    .lower-row{
        margin:25px 10px !important 
    }
    .inner-div{
        min-height: 100px;
        overflow: hidden;
        height: auto !important;    
    }
}