.privacy-policy-main{
    padding: 0 284px;
    text-align: left;
    margin-bottom: 194px;
    h1{
        font-size: 56px;
        color: #333333;
        font-weight: 500;
    }
    p{
        font-size: 16px;
        font-weight: 500;
    }
    h2{
        font-size: 36px;
        font-weight: 400;
    }
    h3{
        font-size: 24px;
        font-weight: 400;
    }
    ul{
        padding-left: 30px;
    }
    li{
        font-size: 16px;
    }
    h4{
        font-size: 16px;
        font-weight: 700;
    }
    a{
        text-decoration: underline;
        color: #333333;
    }
}