.footer{
    height: 337px;
    background: #242329;
    text-align: left;
    .ant-row{
        padding:80px 60px 20px;
    }
    img{
        // padding-bottom: 20px;
        margin-right: 5px;
    }
    p{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #fff;
        width: 70%;
    }
    h3{
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        line-height: 30px;
        cursor: pointer;
    }
    footer{
        background-color: #151515;
        color: #fff;
        font-size: 16px;        
    }
    .ant-row{
        background-color: #242329;

    }
    .github-button{
        height: 48px;
        width: 118px;
        border-radius: 24px;
        // padding: 12px 24px 12px 16px;
        background: #333333;
        color: #fff;
        border: none;
        span{
            margin-top: 5px;
            padding: 5px 0;
        }
    }
}
@media screen and (max-width : 767px) {
    .footer{
    .ant-row{
        padding:80px 20px 20px;

    }
}
}