.section3{
    padding-top: 75px;
    h1{
        font-size: 48px;
        font-weight: 400;
        line-height: 72px;
        letter-spacing: -0.05em;
        text-align: center;
        color: #000;
    }
    h3{
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: -0.02em;
        text-align: center;
        color: #000;
        padding: 0 30%;
    }
    body{background:#000;padding:0;margin:0}

    body{background:#000;padding:0;margin:0}

    .section{
      width:80%;
      margin:0 auto;
      height:60px;
      position:relative;
      padding:0
    }
    .longSection1{
        height:100px;
    }
    .shortSection1{
        height:60px;
    }

    @keyframes move {
        0% {
            transform: translate3d(-200%, 0, 0)
        }
          }
           .desktopCarousel{
            .ant-collapse-header-text{
              font-size: 20px;
              font-weight: 500;
              line-height: 30px;
              letter-spacing: -0.02em;
              text-align: left;
              color: #333333;
            }
            .ant-collapse-content-box {
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0em;
              text-align: left;
              color: #727272;
              p{
                  margin-bottom: 0;
              }
            }
            .ant-carousel .slick-dots li.slick-active {
              width: 70px;
          }
            .ant-carousel .slick-dots li.slick-active button {
              background: #333333;
              opacity: 1;
              animation: test1 3s;
      
          }
          @keyframes test1 {
            0% {
              width: 0;
              background-color: #333333;
              display: block;
            }
            100% {
              width: 70px;
              background-color: #333333;
              
            }
          }
          .ant-carousel .slick-dots li button {
            background-color: #cccccc;
          }
          }
          .mobileCarousel{
            display: none;
            .ant-collapse-header-text{
              font-size: 20px;
              font-weight: 500;
              line-height: 30px;
              letter-spacing: -0.02em;
              text-align: left;
              color: #333333;
            }
            .ant-collapse-content-box {
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0em;
              text-align: left;
              color: #727272;
              p{
                  margin-bottom: 0;
              }
            }
            .ant-carousel .slick-dots li.slick-active {
              width: 70px;
          }
            .ant-carousel .slick-dots li.slick-active button {
              background: #333333;
              opacity: 1;
              animation: test1 3s;
      
          }
          @keyframes test1 {
            0% {
              width: 0;
              background-color: #333333;
              display: block;
            }
            100% {
              width: 70px;
              background-color: #333333;
              
            }
          }
          .ant-carousel .slick-dots li button {
            background-color: #cccccc;
          }
          .slick-list{
            padding-bottom: 3%;
          }
          }
//           .test{
//             .ant-collapse-header-text{
//               font-size: 20px;
//               font-weight: 500;
//               line-height: 30px;
//               letter-spacing: -0.02em;
//               text-align: left;
//               color: #333333;
//             }
//             .ant-collapse-content-box {
//               font-size: 16px;
//               font-weight: 400;
//               line-height: 24px;
//               letter-spacing: 0em;
//               text-align: left;
//               color: #727272;
//               p{
//                   margin-bottom: 0;
//               }
//             }
//             .ant-carousel .slick-dots li.slick-active {
//               height: 70px;
//           }
//             .ant-carousel .slick-dots li:nth-child(1).slick-active button {
//               background: #333333;
//               opacity: 1;
//               animation: test1 3s;
      
//           }
//           .ant-carousel .slick-dots li:nth-child(2).slick-active button {
//             background: #333333;
//             opacity: 1;
//             animation: test2 3s;
//         }
//         .ant-carousel .slick-dots li:nth-child(3).slick-active button {
//           background: #333333;
//           opacity: 1;
//           animation: test3 3s;
//       }
//       .ant-carousel .slick-dots li:nth-child(4).slick-active button {
//         background: #333333;
//         opacity: 1;
//         animation: test4 3s;
//     }
//     .ant-carousel .slick-dots li:nth-child(5).slick-active button {
//       background: #333333;
//       opacity: 1;
//       animation: test5 3s;
//   }
//           @keyframes test1 {
//             0% {
//               height: 0;
//               background-color: #333333;
//               display: block;
//             }
//             100% {
//               height: 70px;
//               background-color: #333333;
              
//             }
//           }
//           @keyframes test2 {
//             0% {
//               height: 0;
//               background-color: #333333;
//               display: block;
//             }
//             100% {
//               height: 90px;
//               background-color: #333333;
              
//             }
//           }
//           @keyframes test3 {
//             0% {
//               height: 0;
//               background-color: #333333;
//               display: block;
//             }
//             100% {
//               height: 90px;
//               background-color: #333333;
              
//             }
//           }
//           @keyframes test4 {
//             0% {
//               height: 0;
//               background-color: #333333;
//               display: block;
//             }
//             100% {
//               height: 70px;
//               background-color: #333333;
              
//             }
//           }
//           @keyframes test5 {
//             0% {
//               height: 0;
//               background-color: #333333;
//               display: block;
//             }
//             100% {
//               height: 90px;
//               background-color: #333333;
              
//             }
//           }
//           .ant-carousel .slick-dots li button {
//             background-color: #cccccc;
//           }
//           .slick-list{
//             padding-bottom: 3%;
//           }
// .ant-carousel-vertical .slick-dots li button {
//     height: 27px;
// }

// .ant-carousel-vertical .slick-dots {
//   top: 42%;
// }


// .ant-carousel-vertical .slick-dots li:nth-child(1) {

//   top:10px
// }
// .ant-carousel-vertical .slick-dots li:nth-child(2) {
//     // width: 3px;
//     // height: 16px;
//     // margin: 30px 2px;
//     // vertical-align: baseline;
//     top:31px
// }
// .ant-carousel-vertical .slick-dots li:nth-child(2).slick-active {

//   top:41px 
// }

// .ant-carousel-vertical .slick-dots li:nth-child(3) {

//   top:65px
// }
// .ant-carousel-vertical .slick-dots li:nth-child(3).slick-active {

//   top:70px
// }
// .ant-carousel-vertical .slick-dots li:nth-child(4) {

//   top:90px
// }
// .ant-carousel-vertical .slick-dots li:nth-child(4).slick-active {

//   top:100px 
// }
// .ant-carousel-vertical .slick-dots li:nth-child(5) {

//   top:120px
// }
// .ant-carousel-vertical .slick-dots li:nth-child(5).slick-active {

//   top:130px 
// }

//           }
 }
.buyDevice{
    height: 48px;
    width: 134px;
    border-radius: 24px;
    padding: 12px 24px;
    background-color: #21bfca !important;
    font-size: 16px;
    margin: 15px 0 0 10px;
    color: #000 !important;
    border: none !important;
    span{
      padding: 10px;
  }
}
.buyDevice:hover , .buyDevice:active{
  color: #21bfca !important;
  background-color: #fff !important;
  border: 1px solid #21bfca !important;
}
// .slick-dots-bottom{
//   display: block;
//   transform: translateX(0) translateY(-50%) rotate(90deg);
//   position: absolute;
//   top: 17%;
//   right: 25%;
// }


















.longLength-1{
    height:55px;
    animation: div1 5s;
    position: absolute;
    left: 0;
    top: 16px;
}
@keyframes div1 {
    0% {
      height: 85px;
      display: block;
    }
    100% {
      height: 85px;
      
    }
  }
  .shortLength-1{
        position: relative;
  }
  .shortLength-1::after{
    content: '';
    height: 70%;
    width: 3px;
    position: absolute;
    left: 0;
    top: 16px;
    background-color: #cccccc;

  }
.main-1{
    width: 3px;
    height: 70px;
    background: #333333;
    animation: main1 3s;
    position: absolute;
    left: 0;
    
  }
  @keyframes main1 {
    0% {
      height: 0;
      background-color: #333333;
      display: block;
    }
    100% {
      height: 70px;
      background-color: #333333;
      
    }
  }
  .longLength-2{
    height:55px;
    animation: div2 5s;
    position: absolute;
    left: 0;
    top: 71px;
}
// @keyframes div2 {
//     0% {
//       height: 130px;
//       display: block;
//     }
//     100% {
//       height: 130px;
      
//     }
//   }

  .shortLength-2{
    position: relative;
}
.shortLength-2::after{
content: '';
height: 50%;
width: 3px;
position: absolute;
left: 0;
top: 16px;
background-color: #cccccc;

}


  .main-2{
    width: 3px;
    height: 40px;
    background: #cccccc;
    animation: main2 2s;
    position: absolute;
    left: 0;
  }
  .main-2::after {
    animation-fill-mode: forwards;
  }
  // @keyframes main2 {
  //   0% {
  //     height: 0;
  //     background-color: #333333;
  //   }
  //   100% {
  //     height: 100px;
  //     background-color: #333333;
  //   }
  // }
  .longLength-3{
    height:55px;
    animation: div3 5s;
    animation-delay: 10s;
    position: absolute;
    left: 0;
    top: 125px
}
// @keyframes div3 {
//     0% {
//       height: 130px;
//       display: block;
//     }
//     100% {
//       height: 130px;
      
//     }
//   }
  .shortLength-3{
    position: relative;
}
.shortLength-3::after{
content: '';
height: 50%;
width: 3px;
position: absolute;
left: 0;
top: 16px;
background-color: #cccccc;

}
  .main-3{
    // width: 3px;
    // height: 27px;
    // background: #cccccc;
    // animation: main3 2s;
    // position: absolute;
    // left: 0;
    width: 3px;
    height: 95px;
    background: #cccccc;
    animation: main2 2s;
    position: absolute;
    left: 0;
    top: -110px !important;
  }
//   .main-3::after {
//     animation-fill-mode: forwards;
//   }
  // @keyframes main3 {
  //   0% {
  //     height: 0;
  //     background-color: #333333;
  //   }
  //   100% {
  //     height: 100px;
  //     background-color: #333333;
  //   }
  // }
  
  .longLength-4{
    height:55px;
    animation: div4 5s;
    position: absolute;
    left: 0;
    top: 180px;
}
// @keyframes div4 {
//     0% {
//       height: 130px;
//       display: block;
//     }
//     100% {
//       height: 130px;
      
//     }
//   }
  .shortLength-4{
    position: relative;
}
.shortLength-4::after{
content: '';
height: 50%;
width: 3px;
position: absolute;
left: 0;
top: 16px;
background-color: #cccccc;

}
  .main-4{
    width: 3px;
    height: 70px;
    background: #cccccc;
    animation: main4 2s;
    position: absolute;
    left: 0;
    top: -165px;
  }
  .main-4::after {
    animation-fill-mode: forwards;
  }
  // @keyframes main4 {
  //   0% {
  //     height: 0;
  //     background-color: #333333;
  //   }
  //   100% {
  //     height: 70px;
  //     background-color: #333333;
  //   }
  // }
  .longLength-5{
    height:55px;
    animation: div5 5s;
    position: absolute;
    left: 0;
    top:235px
}
// @keyframes div5 {
//     0% {
//       height: 130px;
//       display: block;
//     }
//     100% {
//       height: 130px;
      
//     }
//   }
  .shortLength-5{
    position: relative;
}
.shortLength-5::after{
content: '';
height: 50%;
width: 3px;
position: absolute;
left: 0;
top: 16px;
background-color: #cccccc;

}
  .main-5{
    width: 3px;
    height: 90px;
    background: #cccccc;
    animation: main5 2s;
    position: absolute;
    left: 0;
    top: -218px;
  }
  .main-5::after {
    animation-fill-mode: forwards;
  }
  // @keyframes main5 {
  //   0% {
  //     height: 0;
  //     background-color: #333333;
  //   }
  //   100% {
  //     height: 100px;
  //     background-color: #333333;
  //   }
  // }
  @media screen and (max-width : 767px) {
    .mobileCarousel{
      display: block !important;
    }
    .desktopCarousel{
      display: none;
    }
  }